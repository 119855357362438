.matrix {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  padding: 25px;

  h1 {
    text-align: center;
  }

  .avgColorBoxContainer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;

    .pixelContainer {
      display: flex;

      .pixelTable {
        border-collapse: collapse;
        width: 60px;
        height: 60px;

        td {
          width: 20px;
          height: 20px;
          border: 1px solid black;
        }
      }

      .avgColorView {
        display: flex;
        align-items: center;

        .avgColorBox {
          height: 60px;
          width: 60px;
          border: solid 1px black;
        }
      }
    }
  }
}
