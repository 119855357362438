@import "fonts";
@import "reset";

html, body {
    margin: 0;
    padding: 0;
}

.page {
    margin-top: 20px;

    .details {
        width: 980px;
        margin: 0 auto;
        @media screen and(max-width: 1080px) {
            width: 90%;
        }
    }
}

.loader-container {
    display: flex;
    height: 70vh;
    justify-content: center;
    align-items: center;

    &.small {
        height: unset;
    }
}

.table {
    margin-bottom: 25px;
    .element {
        border-bottom: 1px solid rgb(223, 227, 235);
        display: flex;
        padding: 10px 0;
        .key {
            width: 200px;
        }
        .value {
            font-weight: 500;
            word-wrap: break-word;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden; 
            white-space: nowrap;
        }
    }
}