.navbar {
    height: 65px;
    width: 100%;
    box-shadow: 0 2px 8px #f0f1f2;

    display: flex;
    align-items: center;

    
    .logo {
        img {
            margin-left: 25px;
            width: 35px;
            margin-right: 25px;
        }
        display: flex;
        align-items: center;
        margin: 0;
        text-decoration: none;
        color: black;
        font-weight: 500;
    }

    .search {
        width: 500px;
        margin-left: 100px;
    }

    .connections {
        margin-left: auto;
        margin-right: 25px;
        display: flex;
        align-items: center;
        span {
            margin-left: 5px;
        }
    }
}