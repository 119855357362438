@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-ExtraLight.ttf") format("truetype");
    font-weight: 200;
}
@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Italic.ttf") format("truetype");
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-SemiBold.ttf") format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Josefin Sans";
    src: url("../assets/fonts/JosefinSans-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}