.tx {
    
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    border: solid 1px black;
    border-radius: 5px;
    padding: 25px;

    @media screen and(max-width: 1080px) {
        padding: 15px;
    }

    .details {
        justify-content: space-between;
        display: flex;
        @media screen and(max-width: 1080px) {
            flex-direction: column;
        }
    }

    .top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        a {
            word-break: break-all;
        }

        button {
            margin-left: 15px;
        }

        @media screen and(max-width: 680px) {
            flex-direction: column;
            button {
                margin-left: 0;
                margin-top: 15px;
            }
        }
    }

    svg {
        margin-top: 35px;
        width: 40px;
        height: 50px;
        @media screen and(max-width: 1080px) {
            transform: rotate(90deg);
            width: 100%;
            margin-top: 5px;
            margin-bottom: -15px;
        }
        fill: #2d2b2b;
    }

    .outputs, .inputs {
        display: flex;
        flex-direction: column;
        min-width: 430px;
        position: relative;

        @media screen and(max-width: 680px) {
            min-width: unset;
            width: 100%;
        }
    }
}