.block {
    .details {
        h1 {
            margin-left: -2px;
        }

        

        .elements {
            margin-bottom: 50px;
        }

        

        .element {
            border-bottom: 1px solid rgb(223, 227, 235);
            display: flex;
            padding: 10px 0;
            .key {
                width: 200px;
            }
            .value {
                font-weight: 500;
            }
        }
    }
}

.pixelation {
    margin-bottom: 25px;
    display: flex;
    border: solid 1px black;
    border-radius: 5px;
    justify-content: space-evenly;
}